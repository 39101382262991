.tableFont {
  font-size: 0.85rem;
  @media (max-width: 430px) {
    font-size: 0.75rem;
  }
}

.mainRow {
  cursor: pointer;
  &:hover {
    @media (hover: hover) {
      background: var(--primary-color);
    }
  }
  @media (max-width: 430px) {
    border-bottom: 2px solid var(--primary-color);
  }
}

.subRow {
  font-size: 0.8rem;
  color: var(--primary-color);
  background-color: var(--text-color);
}


tr {
  filter: blur(2px); /* O blur(3px) según lo que prefieras */
  animation: fadeInBlur 0.8s ease-in-out forwards;
}

thead {
  height: 2.2rem;
  position: sticky;
  z-index: 7000;
  top: 50px; // + 60px; // header + sticky advise
  @supports (-moz-appearance: none) {
    top: 50px; // + 60px; /* Estilos específicos para navegadores WebKit */
  }

  @media (min-width: 540px) and (max-width: 767px) {
    top: 50px; // + 75px;
    @supports (-moz-appearance: none) {
      top: 50px; // + 75px; /* Estilos específicos para navegadores WebKit */
    }
  }
  @media (min-width: 768px) and (max-width: 1199px) {
    top: 58.781px; // + 75px;
    @supports (-moz-appearance: none) {
      top: 50px; // + 75px; /* Estilos específicos para navegadores WebKit */
    }
  }
  @media (min-width: 1200px) {
    top: 58.781px; // + 110px;
    @supports (-moz-appearance: none) {
      top: 55.6px; // + 110px; /* Estilos específicos para navegadores WebKit */
    }
  }
}

thead tr {
  color: var(--primary-color);
  background-color: var(--text-color);
}

tr th:first-child {
  @media (min-width: 431px) {
    border-top-left-radius: 5px;
  }
}

tr th:last-child {
  @media (min-width: 431px) {
    border-top-right-radius: 5px;
  }
}

tr td:first-child, tr th:first-child {
  padding-left: 0.4rem;
}

tr td:last-child, tr th:last-child {
  padding-right: 0.4rem;
}

tr td {
  padding: 0.3rem 0.2rem;
  vertical-align: middle;

  @media (max-width: 1199px) {
    padding: 0.7rem 0.2rem;
  }
}

.sortable-header {
  position: relative;
}

.sortable-header::after {
  content: '';
  display: inline-block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border: 8px solid transparent;
}

.sortable-header.asc::after {
  bottom: 0;
  border-bottom-color: var(--primary-color); /* Cambia el color según el estilo */
}

.sortable-header.desc::after {
  top: 0;
  border-top-color: var(--primary-color); /* Cambia el color según el estilo */
}

.skeleton-row {
  filter: blur(11px);
  padding: 0.3rem 0;
  animation: blurAnimation 2s infinite;
}

@keyframes blurAnimation {
  0% {
    filter: blur(11px);
  }
  100% {
    filter: blur(2px);
  }
}

@keyframes fadeInBlur {
  0% {
    filter: blur(2px); /* O blur(3px) */
  }
  100% {
    filter: blur(0);
  }
}

.pagination-controls {
  max-width: 18rem;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  padding: 1rem 0;
  align-items: center;
}

.btn-page {
  border: 2px solid var(--text-color);
  color: var(--text-color);
  &:hover {
    @media (hover: hover) {
      background-color: var(--text-color);
      color: var(--primary-color);
    }
  }
}
